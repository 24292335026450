<template>
  <main class="lg:p-4">
    <sqr-page-header title="Companies d'assurance" class="p-4" />
    <sqr-error-banner :error="loadError" />
    <section>
      <table class="table is-fullwidth is-hoverable">
        <thead>
          <tr>
            <!-- <th>{{$t('insurer_id')}}</th> -->
            <th>{{ $t('insurer_company') }}</th>
            <th>{{ $t('insurer_telephone') }}</th>
            <th>{{ $t('insurer_email') }}</th>
            <th>{{ $t('insurer_bpv') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="insurer in records" :key="insurer.id">
            <!-- <td>{{insurer._id}}</td> -->
            <td>{{ insurer.company }}</td>
            <td>{{ insurer.telephone }}</td>
            <td>{{ insurer.email }}</td>
            <td>{{ insurer.bpv }}</td>
          </tr>
        </tbody>
      </table>
    </section>
  </main>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';

export default {
  name: 'Insurers',
  props: {
    bid: String
  },
  computed: {
    ...mapGetters('insurers', ['records', 'loading', 'loadError'])
  },
  mounted() {
    this.sub({
      path: ['brokers', this.bid],
      orderBy: [['company', 'asc']],
      limit: 100
    });
  },
  methods: {
    ...mapActions('insurers', ['sub'])
  }
};
</script>
